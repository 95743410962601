import React from 'react'

const CancellationRefundPolicy = () => {
    return (
        <div className=' bg-gray-100 w-full flex justify-center items-center'>
            <div className="bg-white p-8 rounded-lg shadow-md w-[90%] my-8">
                <div className='text-[#333] font-bold text-2xl'>
                    <span>Cancellation Refund Policy</span>
                </div>
                <div className='my-6'>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>This cancellation policy outlines about how you can cancel or seek a refund for a product / service that you have purchased through the Platform.  Under this policy:</span>
                    </div>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>(i)	Cancellations will only be considered if the request is made 7 days of placing the order. However, cancellation request may not be entertained if the orders have been communicated to such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an even, you may choose to reject the product at the doorstep.</span>
                    </div>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>(ii) Narayani Company does not accept cancellation requests for perishable items like flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that quality of the product delivered is not good.</span>
                    </div>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>(iii) In case of receipt of damaged or defective items,
                            please report to our customer service team.
                            The request would be entertained once the seller/ merchant listed on the Platform,
                            has checked and determined the same at its own end.
                            This should be reported within 7 days of receipt of products. In case you feel that the product
                            received is not as shown on the site or as per your expectations,
                            you must bring it to the notice of our customer service within 24 hours of receiving the product.
                            The customer service team after looking into your complaint will take an appropriate decision.</span>
                    </div>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>(iv) In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.</span>
                    </div>
                    <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                        <span>(v)	In case of any refunds approved by Narayani Company, it will take 7 days for the refund to he processed to you.</span>
                    </div>

                </div>
                <div className='text-[#333] font-bold text-2xl mt-8'>
                    <span>CANCELLATION OF ORDER</span>
                </div>
                <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                    <span>Cancellations are allowed without any question asked with reversal of full amount paid by the customer. However, it is allowed only till the time product is not shipped from our warehouses. Please contact our Customer Care team immediately at +91-94285-60666 or support@ayubazar.com to assist you in cancellation of the order.</span>
                </div>
                <div className='text-[#333] font-bold text-2xl mt-8'>
                    <span>RETURN OF PRODUCT</span>
                </div>
                <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                    <span>We offer refund / exchange within first 7 days from the date of your purchase. If 7 days have passed since your purchase, you will not be offered return, exchange or refund of any kind. In order to become eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale, then the item may not be eligible for a return / exchange. Further, only such items are replaced by us (based on an exchange request), if such items are found defective or damaged.
                        If you need to place a return / exchange request for an eligible product/ item (as applicable) you need to send us an email support.ayubazar.com
                        You agree that there may be a certain category of products / items that are exempted from returns or refunds. Such categories of the products would be identified to you at the item of purchase. For exchange / return accepted request(s) (as applicable), once your returned product / item is received and inspected by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further. If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will be processed in accordance with our policies.</span>                    
                </div>
                <div className='text-[#333] font-bold text-2xl mt-8'>
                    <span>REFUND OF PAYMENT</span>
                </div>
                <div className='mt-4 flex flex-col space-y-4 text-[#999]'>
                    <span>We will initiate your refund within 7 working days of our confirmation. However, it may take 15 to 30 working days to get it credited/reflected in your bank or credit card account statement as this involves inter-bank refund procedures which may take time.</span>
                    <span>If you have any questions about our cancellation, return & refund policy, please contact us via email at: support@ayubazar.com</span>
                </div>
            </div>
        </div>
    )
}

export default CancellationRefundPolicy;