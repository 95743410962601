import React from 'react'
import ProductEnquiryForm from '../Components/Forms/ProductEnquiryForm';

const ProductEnquiry = () => {
     return (
          <div className='p-4'>
               <ProductEnquiryForm/>
          </div>
     )
}

export default ProductEnquiry;