const ActionType = {
    AUTHENTICATE: "AUTHENTICATE",
    OPENCART: "OPENCART",
    COMPANY: "COMPANY",
    PRODUCTS: "PRODUCTS",
    USER: "USER",
    MYACCOUNTOPTION: "MYACCOUNTOPTION",
    USERWISHLIST: "USERWISHLIST",
    USERLOCALWISHLIST: "USERLOCALWISHLIST",
    SINGLEPRODUCT: "SINGLEPRODUCT",
    USERORDERS: "USERORDERS",
    USERCART: "USERCART",
    USERLOCALCART: "USERLOCALCART",
    TOTAL: "TOTAL",
    DISCOUNT: "DISCOUNT",
    PRODUCTREVIEW: "PRODUCTREVIEW",
    DISCOUNTOFFER: "DISCOUNTOFFER",
    HEADER: "HEADER",
    FOOTER: "FOOTER",
    SHIPPING: "SHIPPING",
    COUPONDISABLE: "COUPONDISABLE",
    SUBTOTAL: "SUBTOTAL",
    COUPON: "COUPON",
    ORDERDETIALS: "ORDERDETIALS",
    ISMOBILEVIEW: "ISMOBILEVIEW",
    USERMENU: "USERMENU",
    FILTEREDPRODUCT: "FILTEREDPRODUCT",
    SHOWORDER: "SHOWORDER",
    DEFAULTPRODUCTS: "DEFAULTPRODUCTS",
    CODCHARGES: "CODCHARGES",
    CODAVAILABLE: "CODAVAILABLE",
    HANDLINGCHARGES: "HANDLINGCHARGES",
    SEARCHEDPRODUCT: "SEARCHEDPRODUCT",
    BESTSELLINGPRODUCT: "BESTSELLINGPRODUCT",
    LATESTPRODUCT: "LATESTPRODUCT",
    NARAYANIPRODUCTS: "NARAYANIPRODUCTS",
    VALIDATEFORM: "VALIDATEFORM",
    CATEGORIES : "CATEGORIES"
}

export default ActionType;